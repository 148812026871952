import React, { useEffect, useState } from "react"
import "./Testimonials.scss"

function shuffle(array) {
  array.sort(() => Math.random() - 0.5)
}

function loadTestimonials(baseTestimonials, names) {
  let result = baseTestimonials
  if (names) {
    result = result.filter(testimonial => {
      return names.indexOf(testimonial.name) !== -1
    })
  }
  return result
}

function Testimonials({ name, baseTestimonials, names, doShuffle }) {
  const [testimonials, setTestimonials] = useState(
    loadTestimonials(baseTestimonials, names)
  )
  const [shuffled, setShuffled] = useState(false)
  useEffect(() => {
    if (!shuffled && doShuffle) {
      shuffle(testimonials)
      setShuffled(true)
      setTestimonials((testimonials)=>(testimonials))
    }
  }, [doShuffle, shuffled,testimonials])
  const [index, setIndex] = useState(0)
  function increase() {
    setIndex((index + 1) % testimonials.length)
  }
  function decrease() {
    setIndex(index === 0 ? testimonials.length - 1 : index - 1)
  }
  return (
    <section className="testimonials">
      <div className="title">Отзывы наших {name}</div>

      <div className="slider">
        <button className="left" onClick={decrease} aria-label="decrease"></button>
        <span>
          {index + 1}/{testimonials.length}
        </span>
        <button className="right" onClick={increase} aria-label="increase"></button>
      </div>

      <div>
        {[0, 1, 2].map(count => {
          let newIndex = index + count
          if (!testimonials[newIndex]) {
            newIndex = newIndex - testimonials.length
          }
          const { logo, text, position, name } = testimonials[newIndex]
          return (
            <div key={count}>
              <div className="image-wrapper">
                <img src={logo} alt={"лого " + name} />
              </div>
              <p>{text}</p>
              <span>{position}</span>
              <span>{name}</span>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Testimonials
